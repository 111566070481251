<script>
    import { onMount } from "svelte";

    let name = "DTurner";
    let occupation = "Cyber Security Student";
    let githubUrl = "https://github.com/dturnerdev";
    let bongoUrl = "https://bongo.dturner.dev/";

    let isDarkMode = false;

    let layers = [
        { opacity: 0.1, speedMultiplier: 0.5, zIndex: 1 },
        { opacity: 0.3, speedMultiplier: 0.75, zIndex: 2 },
        { opacity: 0.6, speedMultiplier: 1, zIndex: 3 },
    ];

    let columns = [];
    let windowWidth = 0;
    let windowHeight = 0;
    let numberOfColumns = 50;

    const initColumns = () => {
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
        numberOfColumns = windowWidth <= 768 ? 20 : 50;
        const columnWidth = windowWidth / numberOfColumns;

        columns = layers.flatMap((layer) =>
            Array(numberOfColumns)
                .fill(0)
                .map((_, i) => ({
                    x: i * columnWidth,
                    y: Math.random() * -windowHeight,
                    text: Math.random() < 0.5 ? "0" : "1",
                    size: Math.random() * 20 + 10,
                    speed: (Math.random() * 30 + 30) * layer.speedMultiplier,
                    opacity: layer.opacity,
                    zIndex: layer.zIndex,
                })),
        );
    };

    const updateColumns = () => {
        columns = columns.map((col) => {
            col.y += (windowHeight / col.speed) * 0.016;
            if (col.y > windowHeight) {
                col.y = -col.size;
                col.text = Math.random() < 0.5 ? "0" : "1";
            }
            return col;
        });
    };

    const handleResize = () => {
        clearTimeout(debouncedResize);
        debouncedResize = setTimeout(initColumns, 250);
    };

    let debouncedResize;

    const toggleDarkMode = () => {
        isDarkMode = !isDarkMode;
    };

    onMount(() => {
        initColumns();
        const interval = setInterval(updateColumns, 16);

        // Check system preference
        const prefersDarkScheme = window.matchMedia(
            "(prefers-color-scheme: dark)",
        );
        isDarkMode = prefersDarkScheme.matches;

        // Listen for changes in system preference
        const handleColorSchemeChange = (e) => {
            isDarkMode = e.matches;
        };
        prefersDarkScheme.addListener(handleColorSchemeChange);

        return () => {
            clearInterval(interval);
            prefersDarkScheme.removeListener(handleColorSchemeChange);
        };
    });
</script>

<svelte:head>
    <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    />
</svelte:head>

<svelte:window on:resize={handleResize} />

<main class:dark-mode={isDarkMode}>
    <button
        class="theme-toggle"
        on:click={toggleDarkMode}
        aria-label="Toggle dark mode"
    >
        <i class="fas {isDarkMode ? 'fa-sun' : 'fa-moon'}"></i>
    </button>

    <!-- Falling Numbers Effect -->
    {#each columns as column}
        <div
            class="number"
            style="left: {column.x}px; top: {column.y}px; font-size: {column.size}px;
             opacity: {column.opacity}; z-index: {column.zIndex};"
        >
            {column.text}
        </div>
    {/each}

    <!-- Static Personal Information -->
    <div class="content">
        <h1>{name}</h1>
        <p class="pad">{occupation}</p>
        <a href={githubUrl} target="_blank" rel="noopener noreferrer">
            <svg
                class="github-icon"
                height="64"
                width="64"
                viewBox="0 0 16 16"
                version="1.1"
                aria-hidden="true"
            >
                <path
                    fill-rule="evenodd"
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.19 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                ></path>
            </svg>
        </a>
        <p><a href={bongoUrl}>Project 01</a></p>
    </div>
</main>

<style>
    :global(body) {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans Mono", monospace;
    }

    main {
        --bg-color: #f5f5f0;
        --text-color: #1e1e1e;
        --number-color: #d6d6d6;
        --hover-color: #ff3e00;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        color: var(--text-color);
        background-color: var(--bg-color);
        transition:
            background-color 0.3s ease,
            color 0.3s ease;
    }

    main.dark-mode {
        --bg-color: #1a1a1a;
        --text-color: #f5f5f0;
        --number-color: #333333;
        --hover-color: #ff7f50;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 10;
    }

    h1 {
        font-size: 3rem;
        margin: 0;
    }

    .pad {
        padding: 0px 0px 15px 0px;
    }

    p {
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }

    a {
        margin-top: 1rem;
        font-size: 2rem;
        color: var(--text-color);
        text-decoration: none;
        transition: color 0.3s ease;
    }

    a:hover {
        color: var(--hover-color);
    }

    .number {
        position: absolute;
        font-family: monospace;
        color: var(--number-color);
        pointer-events: none;
        white-space: nowrap;
    }

    .github-icon {
        transition:
            transform 0.3s ease-in-out,
            fill 0.3s ease-in-out;
        fill: var(--text-color);
    }

    .github-icon:hover {
        transform: scale(1.1);
        fill: var(--hover-color);
    }

    .theme-toggle {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        color: var(--text-color);
        font-size: 1.5rem;
        cursor: pointer;
        z-index: 20;
        transition:
            color 0.3s ease,
            transform 0.3s ease;
        padding: 8px;
        outline: none;
    }

    .theme-toggle:hover {
        color: var(--hover-color);
    }

    .theme-toggle:focus {
        outline: none;
    }

    .theme-toggle:active {
        transform: scale(0.95);
    }

    /* Removes the focus outline for mouse users, but keeps it for keyboard navigation */
    @media (hover: hover) {
        .theme-toggle:focus:not(:focus-visible) {
            outline: none;
        }
    }

    /* Styles for keyboard focus */
    .theme-toggle:focus-visible {
        box-shadow: 0 0 0 2px var(--hover-color);
        border-radius: 50%;
    }
</style>
